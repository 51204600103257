/** @format */

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-mat-form-field-theme($theme) {
  // Define any styles affected by the theme.

  .form-field {
    display: flex;
    width: 284px;
    margin: 0 8px 0 0;

    &.mat-mdc-checkbox {
      margin-bottom: 24px;
    }
  }

  mat-form-field {
    &[color='white'] {
      color: var(--ion-color-white);

      input {
        color: white;
        caret-color: white;
      }

      .mat-form-field-label-wrapper label.mat-form-field-label {
        color: white;
      }

      .mat-form-field-underline {
        background-color: white;

        .mat-form-field-ripple {
          background-color: white;
        }
      }
    }

    &.has-prefix-top {
      .prefix-top {
        position: absolute;
        font-size: 0.7em;
        top: -28px;
        color: var(--ion-color-medium);
      }
    }

    &.has-prefix {
      .mat-form-field-prefix {
        display: inherit;
      }

      .mat-form-field-infix {
        position: inherit;

        .mat-form-field-label-wrapper {
          overflow: visible;
          top: 0;
        }
      }

      &.mat-form-field-disabled mat-label[matprefix] {
        color: rgba(0, 0, 0, 0.38);
      }
    }

    &[readonly]:not([readonly='false']),
    &[readonly='true'] {
      cursor: default;
      pointer-events: none;
      color: rgba(0, 0, 0, 0.54);

      mat-select.mat-select {
        .mat-select-value {
          color: rgba(0, 0, 0, 0.54);
        }

        .mat-select-arrow {
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    &.mat-form-field-disabled {
      .mat-input-element {
        color: var(--ion-color-primary);
      }
    }

    .form-field-clear-btn {
      color: var(--ion-color-warn);
      margin-right: 4px;
    }
  }
}

/** @format */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin app-dialog-theme($theme) {
  .dialog-full {
    max-width: unset !important;
    max-height: unset !important;
    width: 100vw;
    height: 100vh;
    .mat-dialog-content {
      max-height: unset;
    }
  }

  .dialog-confirm {
    .mat-dialog-container {
      padding: 8px 8px 24px 8px;
      mat-dialog-title.mat-dialog-title {
        margin-bottom: 8px;
      }
      .mat-dialog-content {
        margin: 0 -8px;
        padding: 0 16px;
      }
    }
  }

  .dialog-no-padding {
    .mat-dialog-container {
      padding: 0;
      mat-dialog-content.mat-dialog-content {
        margin: 0;
      }
      mat-dialog-actions.mat-dialog-actions {
        margin-bottom: 0;
      }
      .mat-dialog-content {
        margin: 0;
        padding: 0;
      }
    }
  }

  .dialog-no-border-radius {
    .mat-dialog-container {
      border-radius: 0;
    }
  }

  .dialog-no-overflow {
    .mat-dialog-container {
      overflow: hidden;
    }
  }

  .dialog-edit {
    .mat-dialog-container .mat-dialog-surface {
      position: relative;
      padding: 30px 2px 0px 16px;
    }
  }

  @media print {
    app-root {
      display: none !important;
    }

    .cdk-overlay-backdrop {
      display: none;
    }

    html,
    body,
    .cdk-overlay-container,
    .cdk-global-overlay-wrapper,
    .cdk-overlay-pane,
    .mat-dialog-container {
      position: relative !important;
      overflow: auto !important;
      display: block !important;
      max-height: unset !important;
      height: auto !important;
    }
  }
}

/** @format */

// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-tabs-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);

  // Define any styles affected by the theme.

  mat-tab-group.mat-tab-group[hide-disable] {
    & > mat-tab-header.mat-tab-header > .mat-tab-label-container > .mat-tab-list > .mat-tab-labels > .mat-tab-label {
      padding: 0 16px;
      min-width: 140px;

      &[aria-disabled='true'] {
        display: none;
      }
    }
  }

  mat-tab-group.mat-tab-group[header-color='white'] {
    & > mat-tab-header.mat-tab-header {
      background-color: white;
    }
  }

  mat-tab-group.mat-tab-group[no-border] {
    & > mat-tab-header.mat-tab-header {
      border: none;
    }
  }

  mat-tab-group.mat-tab-group[no-label-padding] {
    & > mat-tab-header.mat-tab-header > .mat-tab-label-container > .mat-tab-list > .mat-tab-labels > .mat-tab-label {
      padding: 0;
    }
  }

  mat-tab-group.mat-tab-group[label-color='primary'] mat-tab-header.mat-tab-header mat-tab-label.mat-tab-label {
    color: mat.get-color-from-palette($primary);
  }

  mat-tab-group.mat-tab-group[fill] {
    width: 100%;
    height: 100%;

    .mat-tab-body-wrapper {
      height: 100%;
      width: 100%;
    }
  }

  mat-tab-group.mat-tab-group[dashboard] {
    & > mat-tab-header.mat-tab-header {
      box-sizing: border-box;
      height: 57px;
      margin: 0 360px 0 0;
    }
  }

  mat-tab-group.mat-tab-group[fit-label] {
    & > mat-tab-header.mat-tab-header > .mat-tab-label-container > .mat-tab-list > .mat-tab-labels > .mat-tab-label {
      min-width: inherit;
    }
  }

  [tab-expansion-panel] {
    position: relative;

    & > .tab-expansion-panel-container > mat-tab-group.mat-tab-group,
    & > mat-tab-group.mat-tab-group {
      overflow: hidden;

      & > mat-tab-header.mat-tab-header {
        padding-right: 32px;
      }

      & > .mat-tab-body-wrapper {
        height: 0px;
        transition: height 0.3s ease;
      }
    }

    & > mat-icon.mat-icon {
      cursor: pointer;
      position: absolute;
      top: 12px;
      right: 16px;
      width: 24px;
      height: 24px;
      transition: transform 0.3s ease;
    }

    &.open {
      & > .tab-expansion-panel-container > mat-tab-group.mat-tab-group,
      & > mat-tab-group.mat-tab-group {
        & > .mat-tab-body-wrapper {
          height: var(--height);
        }
      }

      & > mat-icon.mat-icon {
        transform: rotate(180deg);
      }
    }
  }

  nav.mat-tab-nav-bar {
    .mat-tab-link-container {
      .mat-tab-list {
        .mat-tab-link {
          &:hover {
          }

          &:not(.mat-tab-disabled) {
            opacity: 1;
          }
        }

        mat-ink-bar.mat-ink-bar {
          background-color: var(--ion-color-nav-active) !important;
        }
      }
    }

    &[color='primary'] {
      .mat-tab-link-container {
        .mat-tab-list {
          .mat-tab-link {
            color: var(--ion-color-primary);
          }

          mat-ink-bar.mat-ink-bar {
            background-color: var(--ion-color-primary);
          }
        }
      }
    }

    &[mat-align-links='space-arround'] {
      .mat-tab-links {
        place-content: center space-around;
      }
    }

    &[mat-align-links='start'] {
      .mat-tab-links {
        place-content: center start;
      }
    }
  }

  mat-tab-group.mat-tab-group[tab-icon] {
    .mat-tab-label {
      min-width: 56px !important;
      padding: 0 16px !important;

      &.mat-tab-label-active {
        color: var(--ion-color-primary);
      }
    }
  }

  mat-tab-group.mat-primary {
    .mat-tab-list {
      mat-ink-bar.mat-ink-bar {
        background-color: var(--ion-color-nav-active) !important;
      }

      .mat-tab-labels {
        .mat-tab-label-active {
          color: var(--ion-color-primary);
        }
      }
    }
  }

  mat-tab-group.mat-tab-group {
    .mat-tab-label.push-right {
      margin-left: auto;
    }
    .mat-tab-label.push-right + .mat-tab-label.push-right {
      margin-left: inherit;
    }

    .close-btn {
      padding: 0;
      min-width: 48px;
    }
  }
}

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-mapbox-theme($theme) {

  .mapboxgl-canvas {
    outline: none;
  }

  .map-marker {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: -20px 0 0 -20px;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.display {
      opacity: 1;
    }

    .map-marker-letter {
      position: absolute;
      color: white;
      height: 36px;
      width: 40px;
      text-align: center;
      vertical-align: middle;
      font-size: 22px;
      line-height: 36px;
      font-weight: 600px;
    }

    .map-marker-tags {
      position: absolute;
      height: 80px;
      width: 200px;
      background: blue;
      top: 60px;
      left: calc(50% - 100px);
      transition: opacity 0.3s ease;
    }

    &:hover .map-marker-tags {
      display: block;
      opacity: 1;
    }

    .map-marker-arrow {
      position: absolute;
      width: 0;
      height: 0;
      left: calc(50% - 16px);
      top: calc(100% - 8px);
      bottom: -50%;
      border-style: solid;
      border-width: 16px 16px 0 16px;
      border-color: transparent transparent transparent transparent;
      border-radius: 50%;
    }
  }


}

/** @format */

@mixin setColor($name) {
  --app-color-name: #{$name};
  --app-color: var(--app-color-#{$name});
  --app-color-rgb: var(--app-color-#{$name}-rgb);

  --app-color-contrast: var(--app-color-#{$name}-contrast);
  --app-color-contrast-rgb: var(--app-color-#{$name}-contrast-rgb);

  --app-color-shade: var(--app-color-#{$name}-shade);
  --app-color-tint: var(--app-color-#{$name}-tint);

  --app-item-color: var(--app-color);
  --app-item-color-rgb: var(--app-color-rgb);
  --app-item-color-contrast: var(--app-color-contrast);
  --app-item-color-contrast-rgb: var(--app-color-contrast-rgb);
  --app-item-color-shade: var(--app-color-shade);
  --app-item-color-tint: var(--app-color-tint);

  --ion-color-base: var(--app-color) !important;
  --ion-color-base-rgb: var(--app-color-rgb) !important;
  --ion-color-contrast: var(--app-color-contrast) !important;
  --ion-color-contrast-rgb: var(--app-color-contrast-rgb) !important;
  --ion-color-shade: var(--app-color-shadeØ) !important;
  --ion-color-tint: var(--app-color-tint) !important;
}

@mixin reverseColor() {
  --app-item-color: var(--app-color-contrast);
  --app-item-color-rgb: var(--app-color-contrast-rgb);
  --app-item-color-contrast: var(--app-color);
  --app-item-color-contrast-rgb: var(--app-color-rgb);
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary, #3880ff) !important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-primary-contrast-rgb,
    255,
    255,
    255
  ) !important;
  --ion-color-shade: var(--ion-color-primary-shade, #3171e0) !important;
  --ion-color-tint: var(--ion-color-primary-tint, #4c8dff) !important;
}

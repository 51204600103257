/** @format */

@use '@angular/material' as mat;
@import 'shared-variables';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$sesio-primary: (
  50: #e4edf1,
  100: #bcd2dd,
  200: #90b4c7,
  300: #6496b0,
  400: #42809f,
  500: #21698e,
  600: #1d6186,
  700: #18567b,
  800: #144c71,
  900: #0b3b5f,
  A100: #93cbff,
  A200: #60b3ff,
  A400: #2d9aff,
  A700: #148eff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$sesio-accent: (
  50: #fff0e0,
  100: #ffd9b3,
  200: #ffbf80,
  300: #ffa54d,
  400: #ff9226,
  500: #ff7f00,
  600: #ff7700,
  700: #ff6c00,
  800: #ff6200,
  900: #ff4f00,
  A100: #ffffff,
  A200: #fff5f2,
  A400: #ffcfbf,
  A700: #ffbca6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$sesio-warn: (
  50: #fbe1e5,
  100: #f5b3bf,
  200: #ef8095,
  300: #e84d6a,

  400: #e3274a,
  500: #de012a,
  600: #da0125,
  700: #d5011f,
  800: #d10119,
  900: #c8000f,
  A100: #fff1f1,
  A200: #ffbec0,
  A400: #ff8b8e,
  A700: #ff7175,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sesio-app-primary: mat.define-palette($sesio-primary);
$sesio-app-accent: mat.define-palette($sesio-accent);
$sesio-app-warn: mat.define-palette($sesio-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $sesio-app-primary,
      accent: $sesio-app-accent,
      warn: $sesio-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-background-color: #eeeeee;
  --ion-background-color-rgb: 238, 238, 238;
}

@import '_button-theme';
@include app-button-theme($app-theme);

@import 'label-theme';
@include app-label-theme($app-theme);

@import 'icon-theme';
@include app-icon-theme($app-theme);

@import 'mat-form-field-theme';
@include app-mat-form-field-theme($app-theme);

@import '_tabs-theme';
@include app-tabs-theme($app-theme);

@import '_mapbox-theme';
@include app-mapbox-theme($app-theme);

@import '_chip-theme';
@include app-chip-theme($app-theme);

@import '_progress-spinner-theme';
@include app-progress-spinner-theme($app-theme);

@import '_mat-select-theme';
@include app-mat-select-theme($app-theme);

@import '_ion-modal-theme';
@include app-ion-modal-theme($app-theme);

@import '_highchart-theme';
@include app-highchart-theme($app-theme);

@import '_mat-menu-theme';
@include app-mat-menu-theme($app-theme);

@import '_mat-auto-complete-theme';
@include app-mat-auto-complete-theme($app-theme);

@import '_dialog-theme';
@include app-dialog-theme($app-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

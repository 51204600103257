/** @format */

// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-label-theme($theme) {
  // Define any styles affected by the theme.
  mat-label,
  span,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  a {
    &[color] {
      color: var(--app-color);
    }
  }
}

@mixin app-label-typography($config) {
  span,
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    font: {
      family: mat.font-family($config, body-1);
      size: mat.font-size($config, body-1);
      weight: mat.font-weight($config, body-1);
    }
  }
}

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-chip-theme($theme) {

  mat-chip.mat-chip {

    &[color="success"] {
      background-color: var(--ion-color-success);
      color: var(--ion-color-success-contrast);
    }

    &[color="accent"] {
      background-color: var(--ion-color-accent);
      color: var(--ion-color-accent-contrast);
    }

    &[color="primary"] {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
    }

  }

}

/** @format */

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-mat-select-theme($theme) {
  mat-select.mat-select {
    &.mat-select-disabled .mat-select-value {
      color: var(--disabled-color, rgba(0, 0, 0, 0.38));
    }
  }

  .mat-select-panel {
    .mat-option.mat-option-disabled {
      color: var(--disabled-color, rgba(0, 0, 0, 0.38))!important;
    }
  }
}

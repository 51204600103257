// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-highchart-theme($theme) {

  .highcharts-menu {
    hr {
      background-color: var(--ion-color-medium);
    }
  }

  .highcharts-data-table {
    table {
      font-family: Verdana, sans-serif;
      border-collapse: collapse;
      border: 1px solid #EBEBEB;
      margin: 10px auto;
      text-align: center;
      width: 100%;
      max-width: 500px;
    }

    caption {
      padding: 1em 0;
      font-size: 1.2em;
      color: #555;
    }

    th {
      font-weight: 600;
      padding: 0.5em;
      white-space: nowrap;
    }

    td,
    th,
    caption {
      padding: 0.5em;
    }

    thead tr,
    tr:nth-child(even) {
      background: #f8f8f8;
    }

    tr:hover {
      background: #f1f7ff;
    }
  }

}

/** @format */

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-ion-modal-theme($theme) {
  ion-modal {
    &.wide {
      --min-height: 80%;
      --min-width: 80%;
    }

    &.xwide {
      --min-height: 90%;
      --min-width: 90%;
    }

    &.full {
      --min-height: 100%;
      --min-width: 100%;
    }

    &.no-transform {
      .modal-wrapper.ion-overlay-wrapper {
        transform: none !important;
      }
    }

    &.custom-animation {
      .modal-wrapper.ion-overlay-wrapper {
        transform: none !important;
      }
    }

    &.statistics-modal {
      --min-height: 100%;
      --height: 100%;
      --min-width: 100%;
      --width: 100%;
    }
  }

  @media print {
    .ion-page {
      overflow: visible !important;
    }
    // body {
    //   height: auto !important;
    // }
    // ion-modal.sc-ion-modal-md-h {
    //   --overflow: visible;
    //   ion-content {
    //     --overflow: visible;
    //     .inner-scroll.scroll-y {
    //       overflow: visible !important;
    //     }
    //   }
    // }
  }
}

/**
 * /*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 *
 * @format
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~quill-image-uploader/dist/quill.imageUploader.min.css';

// * {
//   scrollbar-color: rebeccapurple green;
// }

ion-label,
ion-text,
a,
span,
p,
h1,
h2,
h3,
h4,
h5 {
  &[bold] {
    font-weight: 600;
  }

  &[light] {
    font-weight: 300;
  }

  &[uppercase] {
    text-transform: uppercase;
  }

  &[underline] {
    text-decoration: underline;
  }

  &[italic] {
    font-style: italic;
  }

  &[size='small'] {
    font-size: 12px;
  }
}

.wide-modal {
  --width: 75%;
  --max-height: 75%;
  --height: 75%;
}

.xwide-modal {
  --width: 90%;
  --max-height: 90%;
  --height: 90%;
}

.modal-over-dialog {
  z-index: 30002 !important;
}

.full-modal {
  --width: 90%;
  --max-height: 90%;
  --height: 90%;
}

.notification {
  --white-space: nowrap;
  --height: 48px;
  --start: calc(100% - 316px);
  --end: 16px;
  top: calc(56px * 0);
  transition: top 0.3s ease;

  &.notification-info {
    --background: var(--ion-color-dark);
    --color: var(--ion-color-white);
  }

  &.notification-warning {
    --background: var(--ion-color-warning);
    --color: var(--ion-color-white);
  }

  &.notification-error {
    --background: var(--ion-color-danger);
    --color: var(--ion-color-white);
  }

  & + & {
    top: calc(56px * 1);
  }

  & + & + & {
    top: calc(56px * 2);
  }

  & + & + & + & {
    top: calc(56px * 3);
  }

  & + & + & + & + & {
    top: calc(56px * 4);
  }
}

.toast-text-centered {
  text-align: center;
}

.cdk-overlay-container {
  z-index: 20002;
}

.cdk-drag-preview {
  z-index: 100000 !important;

  &.table-drag-preview {
    box-sizing: border-box;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    display: table;
    vertical-align: middle;
  }

  &.card-drag-preview {
    position: relative;
    padding: 8px;
    margin: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);

    .remove-data {
      display: none;
    }
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

vg-player {
  video {
    max-width: 100%;
    max-height: 100%;
  }
}

quill-view-html {
  .ql-editor {
    padding: var(--quill-editor-padding, 0);
  }
}

ngx-material-timepicker-container {
  .timepicker.primary {
    --button-color: var(--ion-color-primary);
    --dial-background-color: var(--ion-color-primary);
    --dial-editable-active-color: var(--ion-color-primary);
    --clock-hand-color: var(--ion-color-primary);
  }
}

.inner-scroll {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
}

color-compact-fields {
  .compact-fields {
    .compact-active {
      top: 3px !important;
    }
    color-editable-input {
      span {
        top: 3px !important;
      }
    }
  }
}

/** @format */
@use 'sass:math';
@use 'sass:color';
@import 'mixins';

@function color-contrast($color) {
  $color-brightness: round(math.div((red($color) * 299)+ (green($color) * 587)+ (blue($color) * 114), 1000));
  $light-color: round(math.div((red(#ffffff) * 299)+ (green(#ffffff) * 587)+ (blue(#ffffff) * 114), 1000));
  @if abs($color-brightness) < math.div($light-color, 1.265) {
    @return white;
  } @else {
    @return black;
  }
}

@mixin declareColor($name, $color) {
  $contrast: color-contrast($color);
  --app-color-#{$name}: #{$color};
  --app-color-#{$name}-rgb: #{red($color)}, #{green($color)}, #{blue($color)};
  --app-color-#{$name}-rgba-50: #{rgba(red($color), green($color), blue($color), 0.5)};
  --app-color-#{$name}-rgba-25: #{rgba(red($color), green($color), blue($color), 0.25)};
  --app-color-#{$name}-contrast: #{$contrast};
  --app-color-#{$name}-contrast-rgb: #{red($contrast)}, #{green($contrast)}, #{blue($contrast)};
  --ion-color-#{$name}: var(--app-color-#{$name});
  --ion-color-#{$name}-rgb: var(--app-color-#{$name}-rgb);
  --ion-color-#{$name}-contrast: var(--app-color-#{$name}-contrast);
  --ion-color-#{$name}-contrast-rgb: var(--app-color-#{$name}-contrast-rgb);
}

@mixin declareThemeColor($name, $color) {
  @include declareColor($name, $color);
  [color='#{$name}'],
  .mat-#{$name} {
    @include setColor($name);
  }

  $shadeColor: color.mix(black, $color, 10%);
  @include declareColor(#{$name}-shade, $shadeColor);
  [color='#{$name}-shade'],
  .mat-#{$name}-shade {
    @include setColor(#{$name}-shade);
  }

  $tintColor: color.mix(white, $color, 10%);
  @include declareColor(#{$name}-tint, $tintColor);
  [color='#{$name}-tint'],
  .mat-#{$name}-tint {
    @include setColor(#{$name}-tint);
  }
}

:root {
  @include declareThemeColor(primary, #21698e);

  @include declareThemeColor(secondary, #3dc2ff);
  @include declareThemeColor(tertiary, #5260ff);
  @include declareThemeColor(success, #39bb63);
  @include declareThemeColor(ok, #39bb63);
  @include declareThemeColor(warning, #e6a93a);
  @include declareThemeColor(anomaly, #e6a93a);
  @include declareThemeColor(critical, #ff7f00);
  @include declareThemeColor(danger, #e9396b);
  @include declareThemeColor(failure, #e9396b);
  @include declareThemeColor(accent, #ff7f00);
  @include declareThemeColor(warn, #de012a);
  @include declareThemeColor(dark, #222428);
  @include declareThemeColor(medium, #92949c);
  @include declareThemeColor(light, #f4f5f8);
  @include declareThemeColor(white, #ffffff);
  @include declareThemeColor(black, #000000);
  @include declareThemeColor(close, #000000);
  @include declareThemeColor(state-star, #f8c31d);
  @include declareThemeColor(green-energy, #79b752);
  @include declareThemeColor(nav-active, #fb506f);
  @include declareThemeColor(noflow, #9564e2);
  @include declareThemeColor(my-perimeter, #009688);
  @include declareThemeColor(elevator, #21698e);
  @include declareThemeColor(parking-door, #a0d7ea);
  @include declareThemeColor(optical-fiber, #fb506f);
  @include declareThemeColor(relay-antenna, #9e61ea);
  @include declareThemeColor(photovoltaic-panel, #f8c31d);
  @include declareThemeColor(access-control, #ff7f00);
  @include declareThemeColor(energy-fluid, #79b752);
  @include declareThemeColor(iot, #47abe3);
  @include declareThemeColor(fire-safety, #e7235b);
  @include declareThemeColor(isitech, #63a7cc);
  @include declareThemeColor(isicheck, #0d9e8a);
  @include declareThemeColor(isiservice, #967ab3);
  @include declareThemeColor(isicontrol, #e7235b);
  @include declareThemeColor(maintenance, #ffd238);
  @include declareThemeColor(offline, #e9396b);
  @include declareThemeColor(online, #33a859);
  @include declareThemeColor(nd, #92949c);
  @include declareThemeColor(late, #aaaaaa);
}

.ion-color-critical {
  --ion-color-base: var(--ion-color-critical);
  --ion-color-base-rgb: var(--ion-color-critical-rgb);
  --ion-color-contrast: var(--ion-color-critical-contrast);
  --ion-color-contrast-rgb: var(--ion-color-critical-contrast-rgb);
  --ion-color-shade: var(--ion-color-critical-shade);
  --ion-color-tint: var(--ion-color-critical-tint);
}

.ion-color-accent {
  --ion-color-base: var(--ion-color-accent);
  --ion-color-base-rgb: var(--ion-color-accent-rgb);
  --ion-color-contrast: var(--ion-color-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-accent-shade);
  --ion-color-tint: var(--ion-color-accent-tint);
}

.ion-color-warn {
  --ion-color-base: var(--ion-color-warn);
  --ion-color-base-rgb: var(--ion-color-warn-rgb);
  --ion-color-contrast: var(--ion-color-warn-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warn-contrast-rgb);
  --ion-color-shade: var(--ion-color-warn-shade);
  --ion-color-tint: var(--ion-color-warn-tint);
}

.ion-color-green-energy {
  --ion-color-base: var(--ion-color-green-energy);
  --ion-color-base-rgb: var(--ion-color-green-energy-rgb);
  --ion-color-contrast: var(--ion-color-green-energy-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-energy-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-energy-shade);
  --ion-color-tint: var(--ion-color-green-energy-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-state-star {
  --ion-color-base: var(--ion-color-state-star);
  --ion-color-base-rgb: var(--ion-color-state-star-rgb);
  --ion-color-contrast: var(--ion-color-state-star-contrast);
  --ion-color-contrast-rgb: var(--ion-color-state-star-contrast-rgb);
  --ion-color-shade: var(--ion-color-state-star-shade);
  --ion-color-tint: var(--ion-color-state-star-tint);
}

.ion-color-nav-active {
  --ion-color-base: var(--ion-color-nav-active);
  --ion-color-base-rgb: var(--ion-color-nav-active-rgb);
  --ion-color-contrast: var(--ion-color-nav-active-contrast);
  --ion-color-contrast-rgb: var(--ion-color-nav-active-contrast-rgb);
  --ion-color-shade: var(--ion-color-nav-active-shade);
  --ion-color-tint: var(--ion-color-nav-active-tint);
}

.ion-color-noflow {
  --ion-color-base: var(--ion-color-noflow);
  --ion-color-base-rgb: var(--ion-color-noflow-rgb);
  --ion-color-contrast: var(--ion-color-noflow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-noflow-contrast-rgb);
  --ion-color-shade: var(--ion-color-noflow-shade);
  --ion-color-tint: var(--ion-color-noflow-tint);
}

.ion-color-my-perimeter {
  --ion-color-base: var(--ion-color-my-perimeter);
  --ion-color-base-rgb: var(--ion-color-my-perimeter-rgb);
  --ion-color-contrast: var(--ion-color-my-perimeter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-my-perimeter-contrast-rgb);
  --ion-color-shade: var(--ion-color-my-perimeter-shade);
  --ion-color-tint: var(--ion-color-my-perimeter-tint);
}

.ion-color-elevator {
  --ion-color-base: var(--ion-color-elevator);
  --ion-color-base-rgb: var(--ion-color-elevator-rgb);
  --ion-color-contrast: var(--ion-color-elevator-contrast);
  --ion-color-contrast-rgb: var(--ion-color-elevator-contrast-rgb);
  --ion-color-shade: var(--ion-color-elevator-shade);
  --ion-color-tint: var(--ion-color-elevator-tint);
}

.ion-color-parking-door {
  --ion-color-base: var(--ion-color-parking-door);
  --ion-color-base-rgb: var(--ion-color-parking-door-rgb);
  --ion-color-contrast: var(--ion-color-parking-door-contrast);
  --ion-color-contrast-rgb: var(--ion-color-parking-door-contrast-rgb);
  --ion-color-shade: var(--ion-color-parking-door-shade);
  --ion-color-tint: var(--ion-color-parking-door-tint);
}

.ion-color-optical-fiber {
  --ion-color-base: var(--ion-color-optical-fiber);
  --ion-color-base-rgb: var(--ion-color-optical-fiber-rgb);
  --ion-color-contrast: var(--ion-color-optical-fiber-contrast);
  --ion-color-contrast-rgb: var(--ion-color-optical-fiber-contrast-rgb);
  --ion-color-shade: var(--ion-color-optical-fiber-shade);
  --ion-color-tint: var(--ion-color-optical-fiber-tint);
  --ion-color-tint-contrast: var(--ion-color-optical-fiber-contrast);
}

.ion-color-relay-antenna {
  --ion-color-base: var(--ion-color-relay-antenna);
  --ion-color-base-rgb: var(--ion-color-relay-antenna-rgb);
  --ion-color-contrast: var(--ion-color-relay-antenna-contrast);
  --ion-color-contrast-rgb: var(--ion-color-relay-antenna-contrast-rgb);
  --ion-color-shade: var(--ion-color-relay-antenna-shade);
  --ion-color-tint: var(--ion-color-relay-antenna-tint);
}

.ion-color-photovoltaic-panel {
  --ion-color-base: var(--ion-color-photovoltaic-panel);
  --ion-color-base-rgb: var(--ion-color-photovoltaic-panel-rgb);
  --ion-color-contrast: var(--ion-color-photovoltaic-panel-contrast);
  --ion-color-contrast-rgb: var(--ion-color-photovoltaic-panel-contrast-rgb);
  --ion-color-shade: var(--ion-color-photovoltaic-panel-shade);
  --ion-color-tint: var(--ion-color-photovoltaic-panel-tint);
}

.ion-color-access-control {
  --ion-color-base: var(--ion-color-access-control);
  --ion-color-base-rgb: var(--ion-color-access-control-rgb);
  --ion-color-contrast: var(--ion-color-access-control-contrast);
  --ion-color-contrast-rgb: var(--ion-color-access-control-contrast-rgb);
  --ion-color-shade: var(--ion-color-access-control-shade);
  --ion-color-tint: var(--ion-color-access-control-tint);
}

.ion-color-energy-fluid {
  --ion-color-base: var(--ion-color-energy-fluid);
  --ion-color-base-rgb: var(--ion-color-energy-fluid-rgb);
  --ion-color-contrast: var(--ion-color-energy-fluid-contrast);
  --ion-color-contrast-rgb: var(--ion-color-energy-fluid-contrast-rgb);
  --ion-color-shade: var(--ion-color-energy-fluid-shade);
  --ion-color-tint: var(--ion-color-energy-fluid-tint);
}

.ion-color-iot {
  --ion-color-base: var(--ion-color-iot);
  --ion-color-base-rgb: var(--ion-color-iot-rgb);
  --ion-color-contrast: var(--ion-color-iot-contrast);
  --ion-color-contrast-rgb: var(--ion-color-iot-contrast-rgb);
  --ion-color-shade: var(--ion-color-iot-shade);
  --ion-color-tint: var(--ion-color-iot-tint);
}

.ion-color-fire-safety {
  --ion-color-base: var(--ion-color-fire-safety);
  --ion-color-base-rgb: var(--ion-color-fire-safety-rgb);
  --ion-color-contrast: var(--ion-color-fire-safety-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fire-safety-contrast-rgb);
  --ion-color-shade: var(--ion-color-fire-safety-shade);
  --ion-color-tint: var(--ion-color-fire-safety-tint);
}

.ion-color-isitech {
  --ion-color-base: var(--ion-color-isitech);
  --ion-color-base-rgb: var(--ion-color-isitech-rgb);
  --ion-color-contrast: var(--ion-color-isitech-contrast);
  --ion-color-contrast-rgb: var(--ion-color-isitech-contrast-rgb);
  --ion-color-shade: var(--ion-color-isitech-shade);
  --ion-color-tint: var(--ion-color-isitech-tint);
}

.ion-color-isicheck {
  --ion-color-base: var(--ion-color-isicheck);
  --ion-color-base-rgb: var(--ion-color-isicheck-rgb);
  --ion-color-contrast: var(--ion-color-isicheck-contrast);
  --ion-color-contrast-rgb: var(--ion-color-isicheck-contrast-rgb);
  --ion-color-shade: var(--ion-color-isicheck-shade);
  --ion-color-tint: var(--ion-color-isicheck-tint);
}

.ion-color-isiservice {
  --ion-color-base: var(--ion-color-isiservice);
  --ion-color-base-rgb: var(--ion-color-isiservice-rgb);
  --ion-color-contrast: var(--ion-color-isiservice-contrast);
  --ion-color-contrast-rgb: var(--ion-color-isiservice-contrast-rgb);
  --ion-color-shade: var(--ion-color-isiservice-shade);
  --ion-color-tint: var(--ion-color-isiservice-tint);
}

.ion-color-isicontrol {
  --ion-color-base: var(--ion-color-isicontrol);
  --ion-color-base-rgb: var(--ion-color-isicontrol-rgb);
  --ion-color-contrast: var(--ion-color-isicontrol-contrast);
  --ion-color-contrast-rgb: var(--ion-color-isicontrol-contrast-rgb);
  --ion-color-shade: var(--ion-color-isicontrol-shade);
  --ion-color-tint: var(--ion-color-isicontrol-tint);
}

.ion-color-maintenance {
  --ion-color-base: var(--ion-color-maintenance);
  --ion-color-base-rgb: var(--ion-color-maintenance-rgb);
  --ion-color-contrast: var(--ion-color-maintenance-contrast);
  --ion-color-contrast-rgb: var(--ion-color-maintenance-contrast-rgb);
  --ion-color-shade: var(--ion-color-maintenance-shade);
  --ion-color-tint: var(--ion-color-maintenance-tint);
}

.ion-color-isitech {
  --ion-color-base: var(--ion-color-isitech);
  --ion-color-base-rgb: var(--ion-color-isitech-rgb);
  --ion-color-contrast: var(--ion-color-isitech-contrast);
  --ion-color-contrast-rgb: var(--ion-color-isitech-contrast-rgb);
  --ion-color-shade: var(--ion-color-isitech-shade);
  --ion-color-tint: var(--ion-color-isitech-tint);
}

.ion-color-isicheck {
  --ion-color-base: var(--ion-color-isicheck);
  --ion-color-base-rgb: var(--ion-color-isicheck-rgb);
  --ion-color-contrast: var(--ion-color-isicheck-contrast);
  --ion-color-contrast-rgb: var(--ion-color-isicheck-contrast-rgb);
  --ion-color-shade: var(--ion-color-isicheck-shade);
  --ion-color-tint: var(--ion-color-isicheck-tint);
}

.ion-color-isiservice {
  --ion-color-base: var(--ion-color-isiservice);
  --ion-color-base-rgb: var(--ion-color-isiservice-rgb);
  --ion-color-contrast: var(--ion-color-isiservice-contrast);
  --ion-color-contrast-rgb: var(--ion-color-isiservice-contrast-rgb);
  --ion-color-shade: var(--ion-color-isiservice-shade);
  --ion-color-tint: var(--ion-color-isiservice-tint);
}

.ion-color-isicontrol {
  --ion-color-base: var(--ion-color-isicontrol);
  --ion-color-base-rgb: var(--ion-color-isicontrol-rgb);
  --ion-color-contrast: var(--ion-color-isicontrol-contrast);
  --ion-color-contrast-rgb: var(--ion-color-isicontrol-contrast-rgb);
  --ion-color-shade: var(--ion-color-isicontrol-shade);
  --ion-color-tint: var(--ion-color-isicontrol-tint);
}

.cdk-overlay-container {
  z-index: 30001 !important;
}

ion-label,
ion-note,
ion-text,
mat-label,
a,
span,
p,
h1,
h2,
h3,
h4,
h5 {
  &[capitalized] {
    text-transform: capitalize;
  }

  &[uppercase] {
    text-transform: uppercase;
  }

  &[lowercase] {
    text-transform: lowercase;
  }

  &[center] {
    text-align: center;
  }

  &[underline] {
    text-decoration: underline;
  }

  &[bold] {
    font-weight: 500;
  }

  &[light] {
    font-weight: 300;
  }

  &[regular] {
    font-weight: 400;
  }

  &[size='small'] {
    font-size: 12px;
  }

  &[nowrap] {
    white-space: nowrap !important;
  }

  &[wrap] {
    white-space: normal !important;
  }

  &[ellipsis] {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}

a:not([disabled]) {
  cursor: pointer;
}

ngx-notification-msg {
  .ngx_notification-msg {
    z-index: 99999;
  }
}

mat-step-header.mat-step-header[aria-selected='true'] {
  .mat-step-label {
    color: var(--ion-color-primary);
    border-bottom: 2px solid var(--ion-color-nav-active);
  }
}

input,
textarea {
  &[readonly]:not([readonly='false']),
  &[readonly='true'] {
    cursor: default;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.54);
  }
}

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-mat-menu-theme($theme) {

  .mat-menu-panel {
    .mat-menu-content {
      [cdkdroplist] {
        .cdk-drag-preview {
          box-sizing: border-box;
          border-radius: 4px;
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        }

        .cdk-drag-placeholder {
          opacity: 0;
        }

        .cdk-drag-animating {
          transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }

        .example-box:last-child {
          border: none;
        }

        &.cdk-drop-list-dragging .mat-menu-item:not(.cdk-drag-placeholder) {
          transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
      }
    }
  }

}

/** @format */

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-mat-auto-complete-theme($theme) {
  .mat-autocomplete-panel {
    mat-option.mat-option {
      line-height: 1.5em;
    }
    .option-prefix-top {
      font-size: 0.8em;
      line-height: 0.8em;
      color: var(--ion-color-medium);
    }
  }
}

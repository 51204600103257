/** @format */

// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-button-theme($theme) {
  a,
  button {
    &[mat-icon-button],
    &[mat-fab],
    &[mat-mini-fab] {
      &.mat-button-disabled {
        opacity: 0.4;
      }

      .mat-button-wrapper {
        line-height: 1;
      }
    }

    &.mat-button[color='secondary'] {
      color: var(--ion-color-secondary);

      mat-icon {
        color: var(--ion-color-secondary);
        path {
          fill: var(--ion-color-secondary);
        }
      }
    }

    &.mat-button[color='medium'] {
      color: var(--ion-color-medium);

      mat-icon {
        color: var(--ion-color-medium);
        path {
          fill: var(--ion-color-medium);
        }
      }
    }
  }

  // Define any styles affected by the theme.
  a[mat-raised-button],
  a[mat-icon-button]:not(.icon-only),
  button[mat-raised-button],
  button[mat-icon-button]:not(.icon-only) {
    &[color='iot'] {
      background-color: var(--ion-color-iot);
      color: var(--ion-color-iot-contrast);

      mat-icon {
        color: var(--ion-color-iot-contrast);

        path {
          fill: var(--ion-color-iot-contrast);
        }
      }
    }

    &[color='primary'] {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);

      mat-icon {
        color: var(--ion-color-primary-contrast);

        path {
          fill: var(--ion-color-primary-contrast);
        }
      }
    }

    &[color='secondary'] {
      background-color: var(--ion-color-secondary);
      color: var(--ion-color-secondary-contrast);

      mat-icon {
        color: var(--ion-color-secondary-contrast);

        path {
          fill: var(--ion-color-secondary-contrast);
        }
      }
    }

    &[color='tertiary'] {
      background-color: var(--ion-color-tertiary);
      color: var(--ion-color-tertiary-contrast);

      mat-icon {
        color: var(--ion-color-tertiary-contrast);

        path {
          fill: var(--ion-color-tertiary-contrast);
        }
      }
    }

    &[color='success'] {
      background-color: var(--ion-color-success);
      color: var(--ion-color-success-contrast);

      mat-icon {
        color: var(--ion-color-success-contrast);

        path {
          fill: var(--ion-color-success-contrast);
        }
      }
    }

    &[color='warning'] {
      background-color: var(--ion-color-warning);
      color: var(--ion-color-warning-contrast);

      mat-icon {
        color: var(--ion-color-warning-contrast);

        path {
          fill: var(--ion-color-warning-contrast);
        }
      }
    }

    &[color='critical'] {
      background-color: var(--ion-color-critical);
      color: var(--ion-color-critical-contrast);

      mat-icon {
        color: var(--ion-color-critical-contrast);

        path {
          fill: var(--ion-color-critical-contrast);
        }
      }
    }

    &[color='danger'] {
      background-color: var(--ion-color-danger);
      color: var(--ion-color-danger-contrast);

      mat-icon {
        color: var(--ion-color-danger-contrast);

        path {
          fill: var(--ion-color-danger-contrast);
        }
      }
    }

    &[color='accent'] {
      background-color: var(--ion-color-accent);
      color: var(--ion-color-accent-contrast);

      mat-icon {
        color: var(--ion-color-accent-contrast);

        path {
          fill: var(--ion-color-accent-contrast);
        }
      }
    }

    &[color='warn'] {
      background-color: var(--ion-color-warn);
      color: var(--ion-color-warn-contrast);

      mat-icon {
        color: var(--ion-color-warn-contrast);

        path {
          fill: var(--ion-color-warn-contrast);
        }
      }
    }

    &[color='dark'] {
      background-color: var(--ion-color-dark);
      color: var(--ion-color-dark-contrast);

      mat-icon {
        color: var(--ion-color-dark-contrast);

        path {
          fill: var(--ion-color-dark-contrast);
        }
      }
    }

    &[color='medium'] {
      background-color: var(--ion-color-medium);
      color: var(--ion-color-medium-contrast);

      mat-icon {
        color: var(--ion-color-medium-contrast);

        path {
          fill: var(--ion-color-medium-contrast);
        }
      }
    }

    &[color='light'] {
      background-color: var(--ion-color-light);
      color: var(--ion-color-light-contrast);

      mat-icon {
        color: var(--ion-color-light-contrast);

        path {
          fill: var(--ion-color-light-contrast);
        }
      }
    }

    &[color='white'] {
      background-color: var(--ion-color-white);
      color: var(--ion-color-white-contrast);

      mat-icon {
        color: var(--ion-color-white-contrast);

        path {
          fill: var(--ion-color-white-contrast);
        }
      }
    }

    &[color='black'] {
      background-color: var(--ion-color-black);
      color: var(--ion-color-black-contrast);

      mat-icon {
        color: var(--ion-color-black-contrast);

        path {
          fill: var(--ion-color-black-contrast);
        }
      }
    }

    &[color='green-energy'] {
      background-color: var(--ion-color-green-energy);
      color: var(--ion-color-green-energy-contrast);

      mat-icon {
        color: var(--ion-color-green-energy-contrast);

        path {
          fill: var(--ion-color-green-energy-contrast);
        }
      }
    }
  }

  a.mat-icon-button[size='small'],
  button.mat-icon-button[size='small'] {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;

    .mat-button-wrapper {
      display: block;
      height: 24px;
      width: 24px;
      line-height: 1;
    }

    &.fit-icon {
      width: 20px !important;
      height: 20px !important;
      line-height: 20px !important;
      height: 20px !important;
      width: 20px !important;
    }

    mat-icon.mat-icon {
      height: 24px;
      width: 24px;
      line-height: 24px;
      font-size: 20px;
      display: block;
    }
  }

  a.mat-icon-button[size='xsmall'],
  button.mat-icon-button[size='xsmall'] {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;

    .mat-button-wrapper {
      display: block;
      height: 20px;
      width: 20px;
      line-height: 1;
    }

    &.fit-icon {
      width: 16px !important;
      height: 16px !important;
      line-height: 16px !important;
      height: 16px !important;
      width: 16px !important;
    }

    mat-icon.mat-icon {
      height: 20px;
      width: 20px;
      line-height: 20px;
      font-size: 16px;
      display: block;
    }
  }

  mat-button-toggle-group.mat-button-toggle-group {
    &[color='primary'] {
      .mat-button-toggle-checked {
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
      }
    }

    &[size='small'] {
      mat-button-toggle.mat-button-toggle {
        .mat-button-toggle-label-content {
          line-height: 24px;
          padding: 0 6px;

          mat-icon.mat-icon {
            font-size: 20px;
            width: 20px;
            height: 20px;
            line-height: 20px;
          }
        }
      }
    }
  }

  ion-button.button-xsmall {
    --padding-top: 0;
    --padding-start: 0.2em;
    --padding-end: 0.2em;
    --padding-bottom: 0;
    height: 1.8em;
    font-size: 10px;
  }
}

@mixin app-button-typography($config) {
  button {
    font: {
      family: mat.font-family($config, body-1);
      size: mat.font-size($config, body-1);
      weight: mat.font-weight($config, body-1);
    }
  }
}

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-icon-theme($theme) {

  // Define any styles affected by the theme.
  mat-icon {

    &[color="iot"],
    &.mat-iot {
      color: var(--ion-color-iot);
    }

    &[color="primary"] {
      color: var(--ion-color-primary);
    }

    &[color="secondary"] {
      color: var(--ion-color-secondary);
    }

    &[color="tertiary"] {
      color: var(--ion-color-tertiary);
    }

    &[color="success"] {
      color: var(--ion-color-success);
    }

    &[color="warning"] {
      color: var(--ion-color-warning);
    }

    &[color="critical"] {
      color: var(--ion-color-critical);
    }

    &[color="danger"] {
      color: var(--ion-color-danger);
    }

    &[color="accent"] {
      color: var(--ion-color-accent);
    }

    &[color="warn"] {
      color: var(--ion-color-warn);
    }

    &[color="dark"] {
      color: var(--ion-color-dark);
    }

    &[color="medium"] {
      color: var(--ion-color-medium);
    }

    &[color="medium-tint"] {
      color: var(--ion-color-medium-tint);
    }

    &[color="light"] {
      color: var(--ion-color-light);
    }

    &[color="white"] {
      color: var(--ion-color-white);
    }

    &[color="black"] {
      color: var(--ion-color-black);
    }

    &[size="small"] {
      height: 18px;
      width: 18px;
      line-height: 18px;
      font-size: 18px;
    }

  }

}

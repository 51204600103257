// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-progress-spinner-theme($theme) {

  mat-progress-spinner.mat-progress-spinner {

    &[color="success"] {
      circle {
        stroke: var(--ion-color-success);
      }
    }

    &[color="accent"] {
      circle {
        stroke: var(--ion-color-accent);
      }
    }

    &[color="warn"] {
      circle {
        stroke: var(--ion-color-warn);
      }
    }

    &[color="primary"] {
      circle {
        stroke: var(--ion-color-primary);
      }
    }

  }

}
